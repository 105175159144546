<template>
    <div id="wrapper" class="bg-white pt-4">
        <div class="container">
            <div class="row">
                <h2 class="text-orange ms-4 fw-bold pt-4">Register as a GHS Driver</h2>
                <p class="ms-4">Tell us a bit more about yourself.</p>
            </div>

            <div class="d-flex ms-4 pt-2 pb-2">
                <h4 @click="this.screen = 0">
                    <font-awesome-icon :class="['circle-icon', {'active': screen == 0}]" :icon="['fas', 'user']"></font-awesome-icon>
                </h4>

                <p class="space-icon">&bull;</p>

                <h4 @click="this.screen = 1">
                    <font-awesome-icon :class="['circle-icon', {'active': screen == 1}]" :icon="['fas', 'id-card']"></font-awesome-icon>
                </h4>
                
                <p class="space-icon">&bull;</p>

                <h4 @click="this.screen = 2">
                    <font-awesome-icon :class="['circle-icon', {'active': screen == 2}]" :icon="['fas', 'motorcycle']"></font-awesome-icon>
                </h4>                
            </div>

            <div class="container">
                <div class="row">
                    <status v-model:status_data=status_data></status>
                </div>
            </div>

            <form v-if="screen == 0" id="basic-details" action="#" @submit.prevent="submitUser()" enctype="multipart/form-data">
                <div class="card-body border-bottom-0 p-4">
                    <div class="form-row mt-1">
                        <label for="names" class="fw-bold mb-2">Enter your Names</label>  
                        <div class="input-group">
                            <span class="input-group-text border-right-0">
                                <font-awesome-icon id="eye" :icon="['fa', 'user']" />
                            </span>

                            <input id="names" type="text" class="form-control no-border" v-model="data.user.name" placeholder="Enter your Names" required> 
                        </div>
                        <small v-if="errors.name" class="text-danger">
                            <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                            {{ errors.name }}
                        </small>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-6 col-sm-12" style="margin-top: 2.1rem">
                            <label for="email" class="fw-bold mb-2">Enter your Email</label>
                            <div class="input-group">
                                <span class="input-group-text border-right-0">
                                    <font-awesome-icon id="eye" :icon="['fa', 'envelope']" />
                                </span>
                                
                                <input id="email" type="email" class="form-control no-border" v-model="data.user.email" placeholder="Enter your Email" required>
                            </div>
                            <small v-if="errors.email" class="text-danger">
                                <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                {{ errors.email }}
                            </small>
                        </div>

                        <div class="col-md-6 col-sm-12" style="margin-top: 2.1rem">
                            <label for="phone_number" class="fw-bold mb-2">Enter your Phone Number</label>
                            <div class="input-group">
                                <span class="input-group-text border-right-0">
                                    <font-awesome-icon id="eye" :icon="['fa', 'phone-alt']" />
                                </span>
                                
                                <input id="phone_number" type="number" class="form-control no-border" v-model="data.user.phone_number" placeholder="Enter your Phone Number" required>
                            </div>
                            <small v-if="errors.phone_number" class="text-danger">
                                <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                {{ errors.phone_number }}
                            </small>
                        </div>   
                    </div> 

                    <div class="row">
                        <div class="col-md-6 col-sm-12" id="password-row" style="margin-top: 2.1rem">
                            <label for="password" class="fw-bold mb-2">Enter your Password</label>
                            <div class="input-group">
                                <span class="input-group-text" >
                                    <font-awesome-icon id="lock" :icon="['fa', 'lock']" />
                                </span>

                                <input id="password" type="password" class="form-control" v-model="data.user.password" placeholder="Create Password" required>
                                
                                <span class="input-group-text pe-4" @click="seePassword('password')">
                                    <font-awesome-icon id="eye" :icon="['fa', 'eye']" />
                                </span>
                            </div>
                            <small v-if="errors.password" class="text-danger">
                                <font-awesome-icon :icon="['fas', 'info-circle']"></font-awesome-icon>
                                {{ errors.password }}
                            </small>
                        </div>

                        <div class="col-md-6 col-sm-12" id="cpassword-row" style="margin-top: 2.1rem">
                            <label for="cpassword" class="fw-bold mb-2">Confirm your Password</label>
                            <div class="input-group">
                                <span class="input-group-text" >
                                    <font-awesome-icon id="lock" :icon="['fa', 'lock']" />
                                </span>

                                <input id="cpassword" type="password" class="form-control" v-model="data.user.cpassword" placeholder="Confirm Password" required>
                                
                                <span class="input-group-text pe-4" @click="seePassword('cpassword')">
                                    <font-awesome-icon id="eye" :icon="['fa', 'eye']" />
                                </span>
                            </div>
                        </div>                    
                    </div>

                    <div class="form-row" style="margin-top: 2.3rem; margin-bottom: 0.5rem">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" required>
                        <label class="form-check-label ms-2" for="flexCheckDefault">
                            I have read and accepted the <a class="text-orange text-decoration-none" href="https://gearhealthsystem.com/terms" target="_blank">Terms and Conditions</a>
                        </label>
                    </div>

                    <div class="row" id="login">
                        <div class="col">
                            <button class="btn bg-orange text-white btn-sm mt-4 w-100 text-uppercase fw-bold pt-2 pb-2" type="submit">
                                <span style="letter-spacing: 2px"> Next </span> <font-awesome-icon class="icon ms-1 me-1" :icon="['fa', 'angle-right']" />
                            </button>
                        </div>
                    </div>
                </div>
            </form>            

            <form v-if="screen == 1" id="kyc" action="#" @submit.prevent="submitKyc()">
                <div class="card-body row border-bottom-0 p-4 pt-2">
                    <div class="row mt-2" id="address">
                        <label for="address" class="fw-bold mb-2">Enter your Address</label>  
                        <div class="input-group">
                            <span class="input-group-text" >
                                <font-awesome-icon id="lock" :icon="['fa', 'location-arrow']" />
                            </span>

                            <input id="address" type="address" class="form-control" v-model="data.kyc.address" placeholder="Enter your address." required>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 col-sm-12" style="margin-top: 2.1rem">
                            <label for="idno" class="fw-bold mb-2">Enter your National ID</label>

                            <div class="input-group">
                                <span class="input-group-text">
                                    <font-awesome-icon id="lock" :icon="['fa', 'id-card']" />
                                </span>

                                <input id="idno" type="idno" class="form-control" v-model="data.kyc.idno" placeholder="Enter your National ID Number." required>
                            </div>
                        </div>

                        <div class="col-md-6 col-sm-12" style="margin-top: 2.1rem">
                            <label for="dob" class="fw-bold mb-2">Enter your Date of Birth</label>                                       
                            <div class="input-group">
                                <span class="input-group-text border-right-0">
                                    <font-awesome-icon id="eye" :icon="['fa', 'clock']" />
                                </span>
                                
                                <input id="dob" type="date" class="form-control no-border" v-model="data.kyc.dob" placeholder="Enter your Date of Birth" required>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-6 col-sm-12" style="margin-top: 2.1rem">
                            <label for="gender" class="fw-bold mb-2">Select your Gender</label>                                    
                            
                            <div class="input-group">
                                <span class="input-group-text border-right-0">
                                    <font-awesome-icon id="eye" :icon="['fa', 'venus-mars']" />
                                </span>

                                <select name="gender" id="gender" class="form-select" v-model="data.kyc.gender">
                                    <option value="name">Male</option>
                                    <option value="female">Female</option>
                                </select>
                            </div>
                        </div>                                

                        <div class="col-md-6 col-sm-12" style="margin-top: 2.1rem" id="marital">
                            <label for="marital-status" class="fw-bold mb-2">Select your Marital Status</label>            
                                                        
                            <div class="input-group">
                                <span class="input-group-text" >
                                    <font-awesome-icon id="lock" :icon="['fa', 'ring']" />
                                </span>

                                <select name="marital-status" id="marital-status" class="form-select" v-model="data.kyc.marital">
                                    <option value="single">Single</option>
                                    <option value="married">Married</option>
                                    <option value="divorced">Divorced</option>
                                </select>
                            </div>
                        </div>   
                    </div>

                    <div class="row mt-2" id="login">
                        <div class="col">
                            <button class="btn bg-orange text-white btn-sm mt-4 w-100 text-uppercase fw-bold pt-2 pb-2" type="submit">
                                <span style="letter-spacing: 2px">Next</span> <font-awesome-icon class="icon ms-1 me-1" :icon="['fa', 'angle-right']" />
                            </button>
                        </div>
                    </div>
                </div>
            </form>

            <form v-if="screen == 2" id="bike" action="#" @submit.prevent="submitBike()">
                <div class="card-body row border-bottom-0 p-4 pt-2">
                    <div class="row">
                        <div class="col-md-6 col-sm-12" style="margin-top: 2.1rem;">
                            <label for="bike.registration" class="fw-bold mb-2">Enter your Vehicle Type</label>  
                            <div class="input-group">
                                <span class="input-group-text" >
                                    <font-awesome-icon id="lock" :icon="['fa', 'car']" />
                                </span>

                                <select v-model="data.bike.vehicle_type" id="bike.vehicle_type" class="form-select">
                                    <option></option>
                                    <option value="motorbike">Motorbike</option>
                                    <option value="car">Car</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 col-sm-12" style="margin-top: 2.1rem;">
                            <label for="bike.registration" class="fw-bold mb-2">Enter your Vehicle's Registration No.</label>  
                            <div class="input-group">
                                <span class="input-group-text" >
                                    <font-awesome-icon id="lock" :icon="['fa', 'motorcycle']" />
                                </span>

                                <input id="bike.registration" type="text" class="form-control" v-model="data.bike.registration" placeholder="Enter your bike's registration." required>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 col-sm-12" style="margin-top: 2.1rem;">
                            <label for="bike.license" class="fw-bold mb-2">Upload a picture of your Driving Licence</label>  
                            <div class="input-group">
                                <span class="input-group-text" >
                                    <font-awesome-icon id="lock" :icon="['fa', 'id-card']" />
                                </span>

                                <input id="bike.license" type="file" class="form-control" required>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <button class="btn bg-orange text-white btn-sm mt-4 w-100 text-uppercase fw-bold pt-2 pb-2" type="submit">
                                <span style="letter-spacing: 2px"> Finish </span> <font-awesome-icon class="icon ms-1 me-1" :icon="['fa', 'check-circle']" />
                            </button>
                        </div>
                    </div>                     
                </div>
            </form>            
        </div>

        <main-footer :center=true></main-footer>
    </div>
</template>

<style scoped lang="scss">
    @import '../../assets/scss/base/_base.scss';
    @import '../../assets/scss/base/_fonts.scss';
    @import '../../assets/scss/abstracts/_colors.scss';
    @import '../../assets/scss/helpers/breakpoints.scss';

    .circle-icon {
        border: 1px solid #eee;
        padding: 1.2rem;
        border-radius: 50%;
        font-size: 3rem;
        width: 60px;
        height: 60px;
        margin-top: 10px;

        @include media('<tablet') {
            padding: 1rem;
            width: 50px;
            height: 50px;
        }
    }

    .circle-icon.active {
        width: 80px;
        height: 80px;        
        margin-top: 0;
        color: $orange-color;
        border-color: $orange-secondary-color;

        @include media('<tablet') {
            width: 50px;
            height: 50px;
        }
    }

    .space-icon {
        margin: 2rem 2rem;
        @include media('<tablet') {
            margin: 1.5rem 1rem;
        }
    }


    .input-group {
        .input-group-text {
            background: #eee;
        }

        .form-control, .form-select {
            background: #eee;
        }
    }
    
</style>

<script>
    import Status from '../../components/Status';
    import MainFooter from '../../components/Footer';

    export default {
        name: 'Driver.Register',
        components: {
            Status,
            MainFooter
        },
        data() {
            return {
                user_id: null,
                data: {
                    user: {
                        name: null,
                        email: null,
                        phone_number: null,
                        password: null,
                        cpassword: null,
                    },
                    kyc: {
                        dob: null,
                        gender: null,
                        address: null,
                        marital: null,
                        idno: null,
                        user_id: null
                    },
                    bike: {
                        registration: null,
                        license: null,
                        user_id: null,
                        vehicle_type: null
                    }
                },
                status_data: {
                    "success": false,
                    "success_message": '',
                    "error": false,
                    "error_message": '',
                },
                loading: false,
                screen: 0,
                errors: []
            }
        },
        methods: {
            submitUser() {
                this.status_data.loading = true
                let validatePassword = this.validatePassword()

                if(validatePassword == true) {
                    this.axios.post('api/register', this.data.user).then((response) => {
                        this.status_data.loading = false
                        this.status_data.success = true
                        this.status_data.success_message = 'Got it! Next step.'
                        this.status_data.error = false
                        this.data.kyc.user_id = response.data.data.id
                        this.data.bike.user_id = response.data.data.id 
                        
                        setTimeout(() => {
                            this.status_data.success = false
                            this.addScreen()
                        }, 2000);
                    }).catch((errors) => {
                        this.status_data.loading = false
                        this.status_data.success = false
                        this.status_data.error = true
                                            
                        if(errors.data) {
                            this.errors = errors.data
                        }

                        if(errors.message) {
                            this.status_data.error_message = 'Error! ' + errors.message
                        }
                    })   
                } else {
                    this.status_data.loading = false
                    this.status_data.success = false
                    this.status_data.error = true

                    this.status_data.error_message = validatePassword.message                 
                }
            }, 
            validatePassword() {                    
                if(this.data.user.password == this.data.user.cpassword) {
                    if(this.data.user.password.length > 7) {
                        return true
                    } else {
                        return {
                            status: false,
                            message: "Password length is too short."
                        }
                    }
                } else {
                    return {
                        status: false,
                        message: "Your passwords do not match."
                    }                    
                }
            },
            seePassword(id) {
                if(document.querySelector('#' + id).type == 'password') {
                    document.querySelector('#' + id).type = 'text'
                } else {
                    document.querySelector('#' + id).type = 'password'
                }
            },            
            addScreen() {
                this.screen++
            },
            removeScreen() {
                this.screen--  
            },
            submitKyc() {
                this.axios.post('api/kyc', this.data.kyc).then(() => {
                    this.status_data.loading = false
                    this.status_data.success = true
                    this.status_data.error = false
                    this.status_data.success_message = 'We got it! Last step!'

                    setTimeout(() => {
                        this.status_data.success = false
                        this.addScreen()
                    }, 2000);                    
                }).catch((errors) => {
                    this.status_data.loading = false
                    this.status_data.success = false
                    this.status_data.error = true
                                                                
                    if(errors.data) {
                        this.errors = errors.data
                    }

                    if(errors.message) {
                        this.status_data.error_message = 'Error! ' + errors.message
                    }

                    console.log(errors);
                })
            },
            submitBike() {
                let formData = new FormData()
                let image = document.getElementById('bike.license').files[0]

                formData.append("registration", this.data.bike.registration)
                formData.append('license', image)
                formData.append('user_id', this.data.bike.user_id)
                formData.append('vehicle_type', this.data.bike.vehicle_type)

                this.axios.post('api/driver', formData).then(() => {
                    this.status_data.loading = false
                    this.status_data.success = true
                    this.status_data.error = false
                    this.status_data.success_message = 'Success! Full Registration Process complete.'

                    setTimeout(() => {
                        this.$router.push({name: 'Login'});
                    }, 1500);                    
                }).catch((errors) => {
                    this.status_data.loading = false
                    this.status_data.success = false
                    this.status_data.error = true
                    
                    if(errors.data) {
                        this.errors = errors.data
                    }

                    if(errors.message) {
                        this.status_data.error_message = 'Error! ' + errors.message
                    }
                })
            }
        }
    }
</script>